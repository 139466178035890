<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <!-- <TemplateTwoAndFiveList2 :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateTwoAndFiveList2> -->
          <div class="box-div" v-for="(item,index) in listData" :key="index">
            <van-collapse v-model="item.activeNames">
              <van-collapse-item :title="item.Headlines" name="1">  
                <div v-for="(item2,index2) in item.HeadlinesList" :key="index2" class="setborder">
                  <div style="display: flex;align-items: center;margin-top:10px">
                    <div class="title2">
                      {{item2.title}} 
                    </div>
                    <van-popover
                      v-if="item2.tips"
                      v-model="item2.tipsStatus"
                      theme="dark"
                      trigger="click"
                      :placement="item2.tipsWeiZhi ? item2.tipsWeiZhi : 'right'"
                      style="margin-left: 5px;"
                    >
                      <div v-html="item2.tips" style="max-width: 100px;padding: 15px">
                      </div>
                      <template #reference>
                        <van-icon name="info" color="#A3A3A3"/>
                      </template>
                    </van-popover>
                  </div>
                  <div class="box-div-dis">
                    <div class="box-div-dis-zi" @click="selectBtn(item2.title, zi, item2.fenzhi, index3)" v-for="(zi,index3) in item2.columns" :key="index3">
                      <span style="margin-top: 2px">
                        <svg v-if="compD(item2.title, zi)" t="1718613548086" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2728" width="18" height="18"><path d="M512 985C250.77 985 39 773.23 39 512S250.77 39 512 39s473 211.77 473 473-211.77 473-473 473z" fill="#2F54EB" p-id="2729"></path><path d="M391.84 648.34l321.887-321.635c15.627-15.615 40.953-15.605 56.568 0.022 15.615 15.627 15.605 40.953-0.022 56.568L419.957 733.338c-15.679 15.666-41.108 15.597-56.7-0.156L201.571 569.845c-15.541-15.7-15.412-41.027 0.288-56.568 15.7-15.541 41.026-15.413 56.568 0.288L391.84 648.34z" fill="#FFFFFF" p-id="2730"></path></svg>
                        <svg v-else t="1718613083329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1582" width="18" height="18"><path d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m0-34.133333c263.918933 0 477.866667-213.947733 477.866667-477.866667S775.918933 34.133333 512 34.133333 34.133333 248.081067 34.133333 512s213.947733 477.866667 477.866667 477.866667z" fill="#999999" p-id="1583"></path></svg>
                      </span>
                      <span style="margin-left: 8px;word-break:break-all">{{zi}}</span>
                    </div>
                  </div>
                </div> 
                <van-form @submit="submit" v-if="item.Completion === ''" class="vantForm">
                    <van-field
                      v-model="GCs"
                      :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
                    >
                    <template #input>
                      <div class="titles">
                        <span class="titlesName">GCS<i>*</i></span>
                       <span style="display: flex;align-items: center;">
                         <span class="information">
                           <van-cell-group class="vanCell">
                             <van-field v-model="GCs" placeholder="请输入3-15的数值" />
                           </van-cell-group>
                         </span>
                         <div class="unit"> <span>分</span></div>
                       </span>
                      </div>
                    </template>
                   </van-field>
                  </van-form>
              </van-collapse-item>
            </van-collapse>
          </div>

          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
        </div>
      </div>
      <CalculationResults :countText="countValue" :countTextList=[] v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import router from "@/router";
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
//   import TemplateTwoAndFiveList2 from "@/components/Template2+5/TemplateTwoAndFiveList2";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  watch: {
    "GCs": {
      immediate: true,
      handler: function () {
        this.show = false;
      }
    },
  },
  data(){
    return{
      GCs:'',
      // activeNames:[],
      // activeNames:[],
      compute:true,
      show:false,
      countValue:"",
      listSelect: [],
      listData: [
        {
          Headlines:'年龄评分',
          activeNames:['1'],
          HeadlinesList:[
            {
              title: '年龄（岁）',
              columns: ['≥75', '65-74', '55-64','45-54','≤44'],
              fenzhi: [6,5,3,2,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        },
        {
          Headlines:'急性生理评分（APS）',
          Completion:'',
          activeNames:['1'],
          HeadlinesList:[
            {
              title: '体温T（℃）',
              columns: ['≥41','39-40.9','38.5-38.9', '36-38.4', '34-35.9','32-33.9','30-31.9','≤29.9'],
              fenzhi: [4,3,1,0,1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '平均动脉压（mmHg）',
              columns: ['≥160', '130-159', '110-129','70-109','50-69','≤49'],
              fenzhi: [4,3,2,0,2,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '心率RR（次/min）',
              columns: ['≥180', '140-179', '110-139','70-109','55-69','40-54','≤39'],
              fenzhi: [4,3,2,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '呼吸频率HR（次/min）',
              columns: ['≥50', '35-49', '25-34','12-24','10-11','6-9','≤5'],
              fenzhi: [4,3,1,0,1,2,4] ,//分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
              tips: '按照实际呼吸频率评分（无论是否使用机械通气）',
            },
            {
              title: '氧合指数',
              columns: ['<50%', '≥50%'],
              fenzhi: [0,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '血气结果',
              columns: ['有', '无'],
              fenzhi: [0,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            // {
            //   title: 'pH',
            //   columns: ['≥7.7', '7.6-7.69', '7.5-7.59','7.33-7.49','7.25-7.32','7.15-7.24','<7.15'],
            //   fenzhi: [4,3,1,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            // },
            // {
            //   title: 'HCO₃⁻ 单位：mmol/L',
            //   columns: ['≥52', '41-51.9', '32-40.9','22-31.9','18-21.9','15-17.9','<15'],
            //   fenzhi: [4,3,1,0,1,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            // },
            {
              title: '血钠（mmol/L）',
              columns: ['≥180', '160-179', '155-159','150-154','130-149','120-129','111-119','≤110'],
              fenzhi: [4,3,2,1,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '血钾（mmol/L）',
              columns: ['≥7', '6-6.9', '5.5-5.9','3.5-5.4','3-3.4','2.5-2.9','<2.5'],
              fenzhi: [4,3,1,0,1,2,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '血肌酐（mg/dl）',
              columns: ['≥3.5', '2.0-3.4', '1.5-1.9','0.6-1.4','<0.6'],
              fenzhi: [4,3,2,0,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '是否急性肾功能衰竭',
              columns: ['是', '否'],
              fenzhi: [0,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '血细胞比容HCT（%）',
              columns: ['≥60', '50-59.9', '46-49.9','30-45.9','20-29.9','＜20'],
              fenzhi: [4,2,1,0,2,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '白细胞计数WBC（10^9/L）',
              columns: ['≥40.0', '20.0-39.9', '15.0-19.9','3.0-14.9','1.0-2.9','<1.0'],
              fenzhi: [4,2,1,0,2,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        },
        {
          Headlines:'慢性健康评分',
          activeNames:['1'],
          HeadlinesList:[
            {
              title: '慢性器官功能不全和/或免疫抑制',
              columns: ['无', '择期手术', '非手术','急诊手术'],
              fenzhi: [0,2,5,5] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        },
      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['意识清楚', '轻度意识障碍', '中度意识障碍','昏迷'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
      list: [],
      // showPopover: false,
      fenzhi: [],
      setindex:0,
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
  //   TemplateTwoAndFiveList2,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    selectBtn (title, value, fenzhi, fIndex) {
      this.show = false
      const index = this.list.findIndex((item) => item.title === title)
      if (index === -1) {
        this.list.push({title, value: [value]})
      } else {
        this.$set(this.list, index, {title, value: [value]})
      }
      const indexFz = this.fenzhi.findIndex((item) => item.title === title)
      if (fenzhi) {
        if (indexFz === -1) {
          this.fenzhi.push({title, value: [fenzhi[fIndex]]})
        } else {
          this.fenzhi[indexFz] = {title, value: [fenzhi[fIndex]]}
        }
      }
      var status = false
      var status2 = false
      // 1.选择氧合指数
      this.list.forEach((item,index)=>{
        if(title === '氧合指数' && item.value[0] === '<50%'){
          var paO2 = {
                title: 'PaO₂（mmHg）',
                columns: ['>70', '61-70', '55-60','<55','无结果'],
                fenzhi: [0,1,3,4,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
              }
          if(this.listData[1].HeadlinesList[5].title  === 'A-aDO₂（mmHg）'){
            this.listData[1].HeadlinesList[5] = paO2
          }else if(this.listData[1].HeadlinesList[5].title  === 'PaO₂（mmHg）'){
            return
          }else{
            this.listData[1].HeadlinesList.splice(5,0,paO2) 
          }
          // 删除list里面的选项
          this.list = this.list.filter(item =>{
            return item.title !== 'A-aDO₂（mmHg）'
          })
          this.fenzhi = this.fenzhi.filter(item =>{
            return item.title !== 'A-aDO₂（mmHg）'
          })
        }else if(title === '氧合指数' && item.value[0] === '≥50%'){
          // 如果存在就替换
          var Aado2 = {
              title: 'A-aDO₂（mmHg）',
              columns: ['≥500', '350-499', '200-349','<200','无结果'],
              fenzhi: [4,3,2,0,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            }
          if(this.listData[1].HeadlinesList[5].title  === 'PaO₂（mmHg）'){
            this.listData[1].HeadlinesList[5] = Aado2
          }else if(this.listData[1].HeadlinesList[5].title  === 'A-aDO₂（mmHg）'){
            return
          }else{
            // 不存在就插入
            this.listData[1].HeadlinesList.splice(5,0,Aado2
            )
          }
          // 删除list里面的选项
          this.list = this.list.filter(item =>{
            return item.title !== 'PaO₂（mmHg）'
          })

          this.fenzhi = this.fenzhi.filter(item =>{
            return item.title !== 'PaO₂（mmHg）'
          })
        }else if(title === '血气结果' && item.value[0] === '有'){
          // 血钠的索引号
          var xuenaIndex = this.listData[1].HeadlinesList.findIndex(item => item.title === "血钠（mmol/L）");
         if(this.listData[1].HeadlinesList[xuenaIndex-1].title  === 'HCO₃⁻（mmol/L）'){
            this.listData[1].HeadlinesList[xuenaIndex-1] = 
            {
              title: 'pH',
              columns: ['≥7.7', '7.6-7.69', '7.5-7.59','7.33-7.49','7.25-7.32','7.15-7.24','<7.15'],
              fenzhi: [4,3,1,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            }
         }else if(this.listData[1].HeadlinesList[xuenaIndex-1].title  === 'pH'){
           return
         }else{
          this.listData[1].HeadlinesList.splice(xuenaIndex,0,           
            {
              title: 'pH',
              columns: ['≥7.7', '7.6-7.69', '7.5-7.59','7.33-7.49','7.25-7.32','7.15-7.24','<7.15'],
              fenzhi: [4,3,1,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            }
          )
         }
         // 删除list里面的选项
         this.list = this.list.filter(item =>{
            return item.title !== 'HCO₃⁻（mmol/L）'
          })
         this.fenzhi = this.fenzhi.filter(item =>{
           return item.title !== 'HCO₃⁻（mmol/L）'
         })
        }else if(title === '血气结果' && item.value[0] === '无'){
          var xuena2Index = this.listData[1].HeadlinesList.findIndex(item => item.title === "血钠（mmol/L）");
         if(this.listData[1].HeadlinesList[xuena2Index-1].title  === 'pH'){
            this.listData[1].HeadlinesList[xuena2Index-1] = 
            {
              title: 'HCO₃⁻（mmol/L）',
              columns: ['≥52', '41-51.9', '32-40.9','22-31.9','18-21.9','15-17.9','<15'],
              fenzhi: [4,3,1,0,1,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            }
         }else if(this.listData[1].HeadlinesList[xuena2Index-1].title  === 'HCO₃⁻（mmol/L）'){
           return
         }else{
          this.listData[1].HeadlinesList.splice(xuena2Index,0,           
            {
              title: 'HCO₃⁻（mmol/L）',
              columns: ['≥52', '41-51.9', '32-40.9','22-31.9','18-21.9','15-17.9','<15'],
              fenzhi: [4,3,1,0,1,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          )
         }
         // 删除list里面的选项
         this.list = this.list.filter(item =>{
            return item.title !== 'pH'
          })
         this.fenzhi = this.fenzhi.filter(item =>{
           return item.title !== 'pH'
         })
        }else if( item.value[0] === '是' && item.title === '是否急性肾功能衰竭'){
          status = true
        }else if( item.value[0] === '否' && item.title === '是否急性肾功能衰竭'  ){
          status = false
        }else if(item.title ===  '血肌酐（mg/dl）'){
          status2 = true
        }
        if(title === '血肌酐（mg/dl）' && item.title ==='血肌酐（mg/dl）' ){
          this.setindex = index
          status2 = true
        }
      })
      // 算分
      let fenzhiResult = []
      for (let i = 0; i < this.fenzhi.length; i++) {
        for (let j = 0; j < this.fenzhi[i].value.length; j++) {
          fenzhiResult.push(this.fenzhi[i].value[j])
        }
      }
      //血肌酐是否*2
      if(status2 === true &&status === true ){ 
        fenzhiResult[this.setindex] = fenzhiResult[this.setindex]*2
      }else{
        fenzhiResult[this.setindex] = fenzhiResult[this.setindex]
      }
      // console.log(fenzhiResult)
      this.fenzhiList = fenzhiResult
    },
    compD (title, zi) {
      const list = this.list.filter(item => {
        return item.title === title
      })
      if (list && list.length > 0) {
        return list[0].value.some(item => item === zi)
      }
    },
    coumputTetxt () {
      this.show=true
      let fenshu = 0
      for (let i = 0; i < this.fenzhiList.length; i++) {
        fenshu += this.fenzhiList[i]
      }
      fenshu = fenshu + (15-this.GCs)
      this.result = fenshu
      this.countValue = `${fenshu}分`
      // if(this.result >= 45){
      //   this.countValue = `${fenshu}分 高度存在甲状腺危象`
      // }else if(this.result >= 25 && this.result<= 44 ){
      //   this.countValue = `${fenshu}分 可能存在甲状腺危象`
      // }else if(this.result<= 24){
      //   this.countValue = `${fenshu}分 不大可能存在甲状腺危象`
      // }
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: router.currentRoute.path.substring( 9,router.currentRoute.path.Length),
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.list = res.data.content.data
          this.GCs = res.data.content.GCS.value
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.fenzhiList = [res.data.result.value]
          this.result = res.data.result.value
          this.countValue=res.data.result.DisplayResults
          this.$nextTick(() => {
            this.show=true
          })
        }
      }
    },
    async submit () {
      if ( this.list.length !== 16 || this.GCs === '' ) {
        Toast.fail('请完善选择和填空')
        return
      }
      if(this.GCs<3 || this.GCs>15){
        Toast.fail('GCs限制值为3-15')
        return
      }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        // columnCode: this.tableData.columnCode,  JZZYX
        columnCode: "JZZYX",  
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: this.list,
          GCS: {value: this.GCs, unit: '分'},
        },
        result: {
          value: this.result,
          result: this.result,
          DisplayResults:this.countValue
        }
      }
      // console.log("数据",data)
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      // this.coumputTetxt()
      // const data = {
      //   id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
      //   patientId: this.patientId,
      //   columnCode: this.tableData.columnCode,
      //   formulaCode: this.tableData.code,
      //   channel: this.$router.currentRoute.query.channel,
      //   location: this.$router.currentRoute.query.location,
      //   content: {
      //     shengao: {value: this.shengao, unit: 'cm'},
      //     tizhong: {value: this.tizhong, unit: 'kg'},
      //     nianling: {value: this.nianling, unit: '岁'},
      //     xingbie: {value: this.xingbie == 1 ? '男' : '女'},
      //     // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
      //   },
      //   result: {}
      // }
      // if (this.xingbie == 1) {
      //   data.result.nanxing = {value: this.result, unit: 'kcal'}
      // } else {
      //   data.result.nvxing = {value: this.result, unit: 'kcal'}
      // }
      // const res = await postFormula(data)
      // if (res.code === 0) {
      //   Toast.success('提交成功')
      // } else {
      //   Toast.fail(res.message)
      // }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
// .box-div:first-child{
//   padding:10 0 0px 0px;
// }
.box-div {
// padding-bottom: 10px ;
border-bottom: 1px solid #f0f0f0;
::v-deep  .van-cell {
  padding: 5px 10px;
  font-size: 16px;
  background: #E8F5FF;
  /* margin: 0 5px; */
  box-sizing: border-box;
  // border-left: 8px solid #fff;
  // border-right: 8px solid #fff;
}
.vantForm{
border-top: 1px solid #f0f0f0;
::v-deep  .van-cell {
  // padding: 0px 10px;
  font-size:16px;
  background: #fff;
}
}
.title {
/* padding:0px 0 0 10px; */
padding: 5px 8px;
background: #E8F5FF;
width:100%;
box-sizing: border-box;
}
.title2 {
// padding:0px 0 0 10px;
font-size:16px;
color:#323233;
// margin-top:10px;
}
.setborder{
border-top: 1px solid #f0f0f0;
padding:0 16px
}
.setborder:first-child{
border-top: 0px solid #f0f0f0;
padding:0 16px
}
.box-div-dis {
display: flex;
color: #777777;
flex-wrap: wrap;
margin-top: 5px;
font-size:16px;
// padding-left:7px;
}
.box-div-dis2 {
display: flex;
color: #777777;
flex-wrap: wrap;
margin-top: 5px;
padding-left:11px;
}
.box-div-dis-zi {
display: flex;
margin: 7px 0;
min-width: 50%;
}
.form_title{
    padding: 5px 8px;
    background: #E8F5FF;
    width:100%;
    box-sizing: border-box;
  }
.form_2title{
padding: 5px 8px;
background: #E8F5FF;
width:100%;
box-sizing: border-box;
margin-bottom:10px
}
}

.index{
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:20px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    padding: 0 7px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:151px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.Abutton {

}
::v-deep .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
   border-width: 0px 0; 
}
::v-deep .van-cell::after{
border-bottom: 0px solid #ebedf0
}
::v-deep .van-collapse-item__content {
  padding: 0;
  color: #969799;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}
</style>
